<template>
  <div>
    <v-data-table
      :loading="loading"
      :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }"
      :server-items-length="maxRecords"
      :options.sync="options"
      :headers="headers"
      :items="retailersData"
      :search="seachInput"
    >
      <template v-slot:[`item.id_client`]="{ item }">
        <v-flex>
          <v-layout justify-center align-center>
            {{ item.id_client }}
          </v-layout>
        </v-flex>
      </template>
      <template v-slot:[`item.fantasy_name`]="{ item }">
        <v-flex>
          <v-layout>
            {{ item.fantasy_name }}
          </v-layout>
        </v-flex>
      </template>
      <template v-slot:[`item.franchise`]="{ item }">
        <v-flex>
          <v-layout>
            {{ getFranchise(item.franchise).fantasy_name || getFranchise(item.franchise).name || '-' }}
          </v-layout>
        </v-flex>
      </template>
      <template v-slot:[`item.erp_backoffice`]="{ item }">
        <v-flex>
          <v-layout>
            {{ getERP(item.erp_backoffice) }}
          </v-layout>
        </v-flex>
      </template>
      <template v-slot:[`item.erp_pdv`]="{ item }">
        <v-flex>
          <v-layout>
            {{ getERP(item.erp_pdv) }}
          </v-layout>
        </v-flex>
      </template>
      <template v-slot:[`item.cnpj`]="{ item }">
        <v-flex>
          <v-layout justify-center align-center>
            {{ item.cnpj | cnpj }}
          </v-layout>
        </v-flex>
      </template>
      <template v-slot:[`item.total_stores`]="{ item }">
        <v-flex>
          <v-layout justify-center align-center>
            {{ item.total_stores }}
          </v-layout>
        </v-flex>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-flex>
          <v-layout justify-center align-center>
            <v-icon class="mr-1" small :color="getStatusLabel(isActiveLabel(item.active)).color"> fiber_manual_record </v-icon>
            {{ getStatusLabel(isActiveLabel(item.active)).text }}
          </v-layout>
        </v-flex>
      </template>
      <template v-slot:[`item.options`]="{ item }">
        <v-menu :close-on-content-click="false">
          <template #activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="goToEditRetailer(item)">
              <v-list-item-title>Ver detalhes</v-list-item-title>
            </v-list-item>
            <v-list-item @click="copyId(item)">
              <v-list-item-title>Copiar ID</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="item.active" @click="openDialog(item)">
              <v-list-item-title>Cadastrar Churn</v-list-item-title>
            </v-list-item>
            <v-list-item @click="openDialogHistory(item)">
              <v-list-item-title>Histórico de Churn</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="!item.active" @click="unsetRetailerChurn(item)">
              <v-list-item-title>Remover Churn</v-list-item-title>
            </v-list-item>
            <v-list-item style="cursor: pointer;">
              <v-list-item-title @click="deleteRetailer(item)">Deletar</v-list-item-title>
            </v-list-item>
            <dialog-churn
              v-model="dialog"
              :client_id="client_id"
              :retailer-name="retailer_name"
              :retailer-cnpj="retailer_cnpj"
              :value="dialog"
              :data-churn="item_churn"
              :readonly="readonly"
              :is-retailer="true"
              @click="setChurn"
            >
            </dialog-churn>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <dialog-history-churn
      v-model="historyChurn"
      :retailer-name="retailer_name"
      :list-churn="listChurn"
      :loading="loadingHistory"
      :retailer-cnpj="retailer_cnpj"
      :client-id="client_id"
    >
    </dialog-history-churn>
  </div>
</template>

<script>
import { getStatusLabel } from '@/helpers/statusLabel'
import { QUERY_GET_FRANCHISES_PAGINATED } from '@/modules/franchises/graphql'
import { QUERY_GET_ACCOUNTS, QUERY_GET_ERPS } from '@/modules/accounts/graphql'
import {
  QUERY_GET_RETAILERS_PAGINATED,
  MUTATION_DELETE_RETAILER,
  MUTATION_SET_CHURN,
  MUTATION_UNSET_CHURN,
  QUERY_GET_HISTORY_CHURN
} from '@/modules/retailers/graphql'
import DialogChurn from '../../../../../components/atomic-components/molecules/DialogChurn.vue'
import DialogHistoryChurn from '../../../../../components/atomic-components/molecules/DialogHistoryChurn.vue'
export default {
  components: {
    DialogChurn,
    DialogHistoryChurn
  },
  props: {
    search: {
      type: String,
      default: () => ''
    }
  },
  data: () => ({
    retailersData: [],
    franchisesData: [],
    options: {},
    maxRecords: 0,
    deleteLoadingAction: false,
    dialog: false,
    retailer_name: '',
    retailer_cnpj: '',
    client_id: '',
    dialog_action: false,
    data_churn: null,
    alert_model: false,
    alert_message: '',
    alert_title: '',
    alert_color: '',
    alert_icon: '',
    item_churn: {},
    readonly: false,
    historyChurn: false,
    loadingHistory: false,
    listChurn: []
  }),
  computed: {
    headers() {
      return [
        {
          text: 'ID Varejista',
          value: 'id_client',
          align: 'center',
          width: '10%'
        },
        {
          text: 'Nome',
          value: 'fantasy_name',
          width: '15%'
        },
        {
          text: 'Franquia',
          value: 'franchise',
          width: '15%'
        },
        {
          text: 'ERP Retaguarda',
          value: 'erp_backoffice',
          width: '15%'
        },
        {
          text: 'ERP PDV',
          value: 'erp_pdv',
          width: '15%'
        },
        {
          text: 'CNPJ',
          value: 'cnpj',
          align: 'center',
          width: '15%',
          sortable: false
        },
        {
          text: 'Quantidade de Lojas Físicas',
          value: 'total_stores',
          align: 'center',
          sortable: false,
          width: '6%'
        },
        {
          text: 'Status',
          value: 'status',
          sortable: false,
          align: 'center',
          width: '15%'
        },
        {
          text: '',
          value: 'options',
          align: 'center',
          width: '4%'
        }
      ]
    },
    loading() {
      return this.$apollo.loading || this.deleteLoadingAction
    },
    seachInput() {
      let iscnpj = this.search.match(/\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}/g)
      if (iscnpj) {
        return this.search
          .replace('.', '')
          .replace('.', '')
          .replace('-', '')
          .replace('/', '')
      }
      return this.search
    },
    context() {
      return {
        headers: {
          authorization: localStorage.getItem('session_id')
        },
        uri: this.$microservicesUrls['crm']
      }
    }
  },
  apollo: {
    adminAccounts: {
      query: QUERY_GET_ACCOUNTS,
      fetchPolicy: 'network-only',
      variables: {
        franchise: true
      }
    },
    adminERPs: {
      query: QUERY_GET_ERPS,
      fetchPolicy: 'network-only'
    },
    retailers: {
      query: QUERY_GET_RETAILERS_PAGINATED,
      fetchPolicy: 'network-only',
      variables() {
        return Object.assign(
          {},
          { filters: {} },
          {
            pagination: {
              page: this.options.page || 1,
              pageSize: this.options.itemsPerPage || 10,
              search: this.seachInput || null,
              sort: this.options.sortBy || ['_id'],
              sortDirection: this.options.sortDesc && this.options.sortDesc.map(direction => (direction ? 'DESC' : 'ASC'))
            }
          }
        )
      },
      update({ clients: { count, data } }) {
        this.maxRecords = count
        this.retailersData = data
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['crm']
        }
      }
    },
    franchises: {
      query: QUERY_GET_FRANCHISES_PAGINATED,
      fetchPolicy: 'network-only',
      variables() {
        return {
          pagination: {
            page: 1,
            pageSize: 9999,
            search: ''
          }
        }
      },
      update({ franchises: { count, data } }) {
        this.franchisesData = data
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['crm']
        }
      }
    }
  },
  methods: {
    getStatusLabel,
    openDialogHistory(item) {
      this.client_id = item._id
      this.retailer_name = item.fantasy_name
      this.retailer_cnpj = item.cnpj
      this.historyChurn = true
      this.getData(item._id)
    },
    async getData(id) {
      this.loadingHistory = true
      try {
        const variables = {
          client_id: id
        }
        const { data } = await this.$apollo.query({
          query: QUERY_GET_HISTORY_CHURN,
          fetchPolicy: 'network-only',
          variables,
          context: this.context
        })
        this.listChurn = data.getHistoryChurn
      } catch (err) {
        console.log('Failed to get data', err.message)
      } finally {
        this.loadingHistory = false
      }
    },
    goToEditRetailer(retailer) {
      this.$router.push(`/retailers/${retailer._id}/edit/dados-do-varejista`)
    },
    goToSalesRetailer(retailer) {
      this.$router.push(`/retailers/${retailer._id}/edit/vendas`)
    },
    isActiveLabel(active) {
      return active ? 'active' : 'inactive'
    },
    getFranchise(franchiseId) {
      let franchise = this.franchisesData.find(franchise => franchise._id === franchiseId)
      if (!franchise) {
        return this.adminAccounts?.find(franchise => franchise._id === franchiseId) || {}
      } else {
        return franchise
      }
    },
    getERP(erpId) {
      if (!erpId) return '-'
      return this.adminERPs?.find(erp => erp._id === erpId)?.name
    },
    async deleteRetailer(retailer) {
      await this.$confirm({
        message: `Tem certeza que deseja deletar o varejista ${retailer.fantasy_name}?`,
        confirmText: 'Deletar',
        cancelText: 'Cancelar'
      })
        .then(async () => {
          this.deleteLoadingAction = true
          await this.$apollo
            .mutate({
              mutation: MUTATION_DELETE_RETAILER,
              variables: {
                client_id: retailer._id
              },
              context: {
                headers: {
                  authorization: localStorage.getItem('session_id'),
                  retailerid: '62ac7bc52796791fa901cd78'
                },
                uri: this.$microservicesUrls['crm']
              }
            })
            .then(() => {
              this.$apollo.queries.retailers.refetch()
              this.$alert({
                alert_message: `Varejista deletado com sucesso`,
                alert_title: 'Sucesso!',
                alert_color: 'success',
                alert_icon: 'mdi-check-circle'
              })
            })
            .catch(error => {
              console.log(error)
              this.$alert({
                alert_message: 'Falha ao deletar varejista',
                alert_title: 'Erro!',
                alert_color: 'error',
                alert_icon: 'mdi-close-circle'
              })
            })
          this.deleteLoadingAction = false
        })
        .catch(() => false)
    },
    async unsetRetailerChurn(retailer) {
      const confirmation = await this.$confirm({
        title: 'Remover churn',
        message: 'Deseja remover o churn?',
        confirmText: 'Continuar',
        cancelText: 'Cancelar'
      })
        .then(() => true)
        .catch(() => false)
      if (!confirmation) return
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_UNSET_CHURN,
          variables: {
            client_id: retailer._id
          },
          context: {
            uri: this.$microservicesUrls['crm']
          }
        })
        setTimeout(() => {
          this.dialog_action = false
          this.dialog = false
          this.$alert({
            alert_message: `Churn Removido com sucesso`,
            alert_title: 'Sucesso!',
            alert_color: 'success',
            alert_icon: 'mdi-check-circle'
          })
          this.$router.go(this.$router.currentRoute)
        }, 300)
      } catch {
        this.$alert({
          alert_message: `Ops, parece que houve algum erro`,
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    },
    copyId(retailer) {
      navigator.clipboard.writeText(retailer?._id)
      this.$alert({
        alert_message: 'Id copiado',
        alert_title: 'Info!',
        alert_color: 'success',
        alert_icon: 'mdi-check-circle'
      })
    },
    /**
     * Função para abrir o dialog
     * @param {Object} item
     */
    openDialog(item) {
      this.client_id = item._id
      this.retailer_name = item.fantasy_name
      this.retailer_cnpj = item.cnpj
      this.item_churn = item.churn
      this.dialog = true
    },
    async setChurn(data_churn) {
      const confirmation = await this.$confirm({
        title: 'Cadastrar churn',
        message: 'Deseja cadastrar o churn?',
        confirmText: 'Continuar',
        cancelText: 'Cancelar'
      })
        .then(() => true)
        .catch(() => false)
      if (!confirmation) return
      try {
        let phase = this.translatePhase(data_churn.phase)
        let params = {
          cause: data_churn.cause,
          cause_details: data_churn.cause_details,
          details: data_churn.details,
          phase: phase,
          reason: data_churn.reason,
          inactivated_at: data_churn.inactivated_at,
          billed_client: data_churn.billed_client,
          responsible_area: this.translatePhase(data_churn.responsible_area)
        }
        await this.$apollo.mutate({
          mutation: MUTATION_SET_CHURN,
          variables: {
            client_id: data_churn.client_id,
            data: params
          },
          context: {
            uri: this.$microservicesUrls['crm']
          }
        })
        setTimeout(() => {
          this.dialog = false
          this.$alert({
            alert_message: `Churn cadastrado com sucesso`,
            alert_title: 'Sucesso!',
            alert_color: 'success',
            alert_icon: 'mdi-check-circle'
          })
          this.$apollo.queries.retailers.refetch()
        }, 300)
      } catch (error) {
        console.log(error)
        this.$alert({
          alert_message: `Ops, parece que houve algum erro`,
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    },
    translatePhase(phase) {
      switch (phase) {
        case 'Comercial':
          return 'comercial'
        case 'CX':
          return 'cx'
        case 'CS':
          return 'cs'
        case 'Parcerias':
          return 'partnerships'
        case 'Financeiro':
          return 'financial'
        case 'Indústria':
          return 'industry'
      }
    }
  }
}
</script>
